import Paperbase from './paperbase/Paperbase';

function App() {
    return (
	    <div className="App">
	      <Paperbase/>
	    </div>
  );
}

export default App;

