import React from 'react';
import IconButton from '@mui/material/IconButton';

import MyMarkdown from './MyMarkdown';
import { CardMedia, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PeopleIcon from '@mui/icons-material/People';

const members = [
    {
      name: 'Ahsan Khan',
      github: 'ahsank',
      twitter: 'ahsan_khan',
      flag: '',
      city: 'Kirkland, WA',
    },
   ];
   
  
  const str = "Instruction\r\n  ============\r\n  \r\n* Please sign in to take note in markdown\r\n* Uncheck the checkbox to see the formatted text\r\n* You can also move note up, down and collapse or expand\r\n* Also able to do search in the search bar\r\n* The notes are saved in cloud so you can access from another machine\r\n* Note taker doesn't use any information from profile other than email to associate note with account in database\r\n* The data is not shared with any third party\r\n* Access to source code can be provided on request"
  
  export default function About() {
    return (
     <div>
       <Grid container spacing={2}>
         <Grid item>
          <div >
            <MyMarkdown>{str}
            </MyMarkdown>
          </div>
         </Grid>
        </Grid>
        <Grid container spacing={2} >
          {members.map(member => (
            <Grid key={member.name} item xs={12} md={6}>
              <Paper>
                <Grid container wrap="nowrap">
                  <Grid item>
                    <CardMedia
                      image={`https://github.com/${member.github}.png`}
                      title="Avatar"
                    />
                  </Grid>
                  <Grid item>
                    <div>
                      <Typography component="h3" variant="h6">
                        {member.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {member.flag}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {member.city}
                      </Typography>
                      <Grid container>
                        {member.github && (
                          <IconButton
                            aria-label="github"
                            target="_blank" 
                            href={`https://github.com/${member.github}`}
                          > <PeopleIcon />
                            {/*<GitHubIcon fontSize="inherit" />*/}
                          </IconButton>
                        )}
                        {member.twitter && (
                          <IconButton
                            aria-label="twitter"
                            target="_blank" 
                            href={`https://twitter.com/${member.twitter}`}
                          >
                           <PeopleIcon />
                            {/*<TwitterIcon fontSize="inherit" />*/}
                          </IconButton>
                        )}
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>
    )
  }
  