import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import { useColorScheme, useTheme } from '@mui/material/styles';
import { type User } from 'firebase/auth'
// import Tooltip from '@mui/material/Tooltip';
// import { DarkModeOutlined, LightModeOutlined } from '@mui/icons-material';
// import useLocalStorageState from '@mui/utils/useLocalStorageState';
// import useMediaQuery from '@mui/material/useMediaQuery';
// const lightColor = 'rgba(255, 255, 255, 0.7)';

interface HeaderProps {
  onDrawerToggle: () => void;
  logout: () => void;
  login: () => void;
  userProfile: User | null;

}

// export function useColorSchemeShim() {
//   const [mode, setMode] = useLocalStorageState('mui-mode', 'system');
//   const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', { noSsr: true });
//   const systemMode = prefersDarkMode ? 'dark' : 'light';

//   return {
//     mode,
//     systemMode,
//     setMode,
//   };
// }

// function ThemeModeToggle() {
//   // TODO replace with useColorScheme once all pages support css vars
//   const { mode, systemMode, setMode } = useColorSchemeShim();
//   const calculatedMode = mode === 'system' ? systemMode : mode;

//   const theme = useTheme();

//   // Server-side hydration
//   if (mode === null) {
//     return <IconButton color="primary" disableTouchRipple />;
//   }

//   // TODO remove this code branch, all pages should be migrated to use CssVarsProvider
//     return (
//       <Tooltip title={calculatedMode === 'dark' ? 'Turn on the light' : 'Turn off the light'}>
//         <IconButton
//           color="primary"
//           size="small"
//           disableTouchRipple
//           onClick={() => {
//             setMode(calculatedMode === 'dark' ? 'light' : 'dark');
//           }}
//         >
          
//           {calculatedMode === 'dark' ? (
//             <LightModeOutlined />
//           ) : (
//             <DarkModeOutlined />
//           )}
//         </IconButton>
//       </Tooltip>
//     );

// }
export default function Header(props: HeaderProps) {
    const { onDrawerToggle } = props;

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
          </Grid>
          <Grid item xs>
            <Typography color="inherit" variant="h5" component="h1">
              Note taker
            </Typography>
          </Grid>

          <Grid item xs />
          {/* <Grid item>
             <ThemeModeToggle
             />
          </Grid> */}
          <Grid item>
	        {
            props.userProfile ? 
               <Button color="inherit"  sx={{ p: 0.5 }} onClick={props.logout}>Log Out</Button>
	                :
               <Button color="inherit"  sx={{ p: 0.5 }} onClick={props.login}>Log In</Button>
	         }
            </Grid>
            {/*<Grid item>
              <Link
                href="/"
                variant="body2"
                sx={{
                  textDecoration: 'none',
                  color: lightColor,
                  '&:hover': {
                    color: 'common.white',
                  },
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Go to docs
              </Link>
            </Grid>*/}

      {/*<Grid item>
              <Tooltip title="Alerts • No alerts">
                <IconButton color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
              </Grid>*/}
            <Grid item>
              <IconButton color="inherit" sx={{ p: 0.5 }}>
          <Avatar  src= {props.userProfile?.photoURL || "/static/images/avatar/1.jpg"} />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
          {/*<AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Authentication
              </Typography>
            </Grid>
            <Grid item>
              <Button
                sx={{ borderColor: lightColor }}
                variant="outlined"
                color="inherit"
                size="small"
              >
                Web setup
              </Button>
            </Grid>
            <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
        </AppBar>*/}
          {/*<AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0 }}>
        <Tabs value={0} textColor="inherit">
          <Tab label="Users" />
          <Tab label="Sign-in method" />
          <Tab label="Templates" />
          <Tab label="Usage" />
        </Tabs>
        </AppBar>*/}
    </React.Fragment>
  );
}
