import React from 'react';
import MyMarkdown from './MyMarkdown';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';


// // a copy of task array is reversed before rendering, so taskIndex represents entry in reverse array
// // get the actual task array index
// function revIndex(tasks: any[], taskIndex: number) {
//   return tasks.length - taskIndex - 1;
// }
    
  
function escapeRegexCharacters(str: string) {
  str = str.trim();
  if (str === '') return str;
  str =  str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  var strs = str.split(/[ ]/);
  str = strs.filter(val => val.length > 0).map(item => "(?=.*" + item + ")").join("");
  return str;
}
  

interface ISearchResult {
  itemId: number
  title: string;
  noteId: string;
  lines: string[];
}

interface IContent {
  text: string;
}
interface AllContents {
  [key: string]: IContent;
}

interface INoteSearhProp {
  tasks: Array<{title: string; noteId: string; text?: string;}>;
  texts: AllContents;
  onTitleClick(taskIndex: number): void;
}


// type Props = {
//   children?: React.ReactNode
// };
  
// // https://stackoverflow.com/questions/71942822/materialui-v5-how-to-style-autocomplete-options
// function CustomPaper({ children }: Props) {
//   return (
//     <Paper 
//       sx={{
//         userSelect: 'unset',
//         "& .MuiAutocomplete-listbox": {
//           "& .MuiAutocomplete-option[aria-selected='true']": {
//             bgcolor: "purple",
//             userSelect: 'unset',
//             "&.Mui-focused": {
//               userSelect: 'unset',
//               bgcolor: "purple",
//             }
//           }
//         },
//         "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
//           userSelect: 'unset',
//           bgcolor: "red",
//         }
//       }}
//     >
//       {children}
//     </Paper>
//   );
// }
// PaperComponent={CustomPaper}


function getSuggestions(value:string,
  tasks:Array<{title: string; noteId: string; text?: string}>,
  taskText: AllContents) {
  if (value.length < 2) return []
  const escapedValue = escapeRegexCharacters(value.trim());

  if (escapedValue === '') {
      return [];
  }
  const regex = new RegExp(escapedValue, 'i');
  const resultLimit = 10;    

  return tasks
    .map((section, idx) => {
        // Search term1 term2 should match if term1 is in title and term2 is in a line vice versa
        // Or both term1 and term2 is in a line in any order
        const content = taskText[section.noteId];
        const text = content ? content.text : (section.text ?? "");
        return {
            itemId: idx,
            title: section.title,
            noteId: section.noteId,
            lines: text.split('\n').filter(line => regex.test(section.title + ':' + line)).slice(0, resultLimit)
        };
      })
      .filter(item => item.lines.length > 0);
}
export default function NoteSearch({tasks, texts, onTitleClick}: INoteSearhProp) {
    // Value chosen by user, currently not used
    // const [value, setValue] = React.useState<ISearchResult | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    // Options displayed in dropdown
    const [options, setOptions] = React.useState<readonly ISearchResult[]>([]);
    // const loaded = React.useRef(false);


    React.useEffect(() => {
        // let active = true;
    
        if (inputValue === '') {
          setOptions([]);
          return undefined;
        }
        let suggestions = getSuggestions(inputValue, tasks, texts)
            
        setOptions(suggestions);
    
        return () => {
          // active = false;
        };
      }, [tasks, inputValue]);

      // Add following to select a value 
      //           value={value}
      // onChange={(event: any, newValue: ISearchResult | null) => {
      //   setOptions(newValue ? [newValue, ...options] : options);
      //   setValue(newValue);
      // }}
    return (
        <Autocomplete
          id="searchnotes"
          getOptionLabel={(option) =>
            typeof option === 'string' ? option : option.title
          }
          filterOptions={(x) => x}
          options={options}
          freeSolo
          autoComplete
          includeInputInList
          filterSelectedOptions
          noOptionsText="No result"
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField {...params}  variant="standard" fullWidth />
          )}
          renderOption={(props, option) => {
            const part = option
            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: 'flex',  margin: "1em"}}>
                    <Typography>
                      <Link
                        onClick={(e) => onTitleClick(part.itemId)}
                        href={"#iid-" + part.noteId}>
                        <strong>{part.title}</strong>
                      </Link>
                  </Typography>
                  </Grid>
                  <Grid item sx={{ width: 'calc(100% - 44px)' , wordWrap: 'break-word' }}>
                      <Box
                        component="span"
                      >
                        {/*part.title */}
                    </Box>
                    {part.lines.map((line, index) =>
                    <MyMarkdown>
                      {line}
                    </MyMarkdown>
                    )}
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      );
  }
