import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import { db } from "../config/firebase";
import { type User } from 'firebase/auth'
import update from 'immutability-helper';
import { collection, doc, onSnapshot, setDoc, deleteDoc, query} from "firebase/firestore";

import NoteSearch from './NoteSearch';
import Note from './Note';

interface ContentProps {
  userProfile: User | null;

}
interface INote {
  noteId: string;
  title: string;
  text?: string;
  collapsed: boolean;
  isedit: boolean;
}

interface IContent {
  text: string;
}
interface AllContents {
  [key: string]: IContent;
}

// Generate random string with increasing length until it doesn't exist in the set
function generateUniqueString(set:Set<string>, minlength:number=2) {
  let length = minlength;

  while (true) {
    let randomString = generateRandomString(length);

    if (!set.has(randomString)) {
      set.add(randomString);
      return randomString;
    }

    length++;
  }
}

function generateRandomString(length:number) {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return result;
}

// // a copy of task array is reversed before rendering, so taskIndex represents entry in reverse array
// // get the actual task array index
// function revIndex(tasks: INote[], taskIndex: number) {
//   return tasks.length - taskIndex - 1;
// }

function getNoteIdSet(arr: INote[]): Set<string> {
  const result = new Set<string>();
  for (const item of arr) {
    if (item.noteId) {
      result.add(item.noteId);
    }
  }
  return result;
}

export default function Content(props: ContentProps) {
  const [tasks, setTasks] = React.useState<Array<INote>>([{
     title: "Instruction",
     noteId: "1",
     collapsed: false, isedit: false }
  ]);

  const [contents, setContents] = React.useState<AllContents>(
    {"1":  {
      "text" : "An Instruction",
    }}
  );
  
  React.useEffect(() => {
      if (props.userProfile == null) return;
      const email: string = props.userProfile.email as string
      const coll = collection(db, 'taskCollection');
      return onSnapshot(doc(coll, email), (snap) => {
        if (snap.metadata.hasPendingWrites) {
          // Not server change, ignore as we handle it before
          return
        }
        const docs = snap.data();
        if (docs !== undefined && "allnotes" in docs) {
          const notes = docs["allnotes"] as INote[];
          var idSet = getNoteIdSet(notes);
          var modified = false; 
          const newNotes = notes.map(note  => {
            if (!note.noteId) {
              note.noteId = generateUniqueString(idSet);
              modified = true;
            }
            if (note.text) {
              if (onTaskUpdate(note.noteId, note.text)) {
                const {["text"]: _, ...rest} = note;
                note = rest as INote;
              }
            }
            return note;
          });
          if (modified) {
            saveData(newNotes);
          }
          setTasks(newNotes)
        }
      });
  }, [props.userProfile]);

  const addOrUpdateNoteText = (noteId: string, noteText: string) => {
      setContents((prev) => 
        ({...prev, [noteId]: {text: noteText}}))
  };

  React.useEffect(() => {
    if (props.userProfile == null) return;
    const email: string = props.userProfile.email as string
    const q = query(collection(db, 'taskCollection', email, "notes"));
    return onSnapshot(q, (querysnap) => {
      if (querysnap.metadata.hasPendingWrites) {
        // Not server change, ignore as we handle it before
        return
      }
      var states = {...contents};
      querysnap.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified") {
          states[change.doc.id] = {text: change.doc.data().text };
        } else if (change.type === "removed") {
          delete states[change.doc.id];
        }
      });
      setContents(states);
    });
  }, [props.userProfile]);

  function handleExpandClick(taskIndex: number) {
    // taskIndex = revIndex(tasks, taskIndex);
    const state2 = update(tasks, { [taskIndex]: { $toggle: ['collapsed'] } });
    saveData(state2);
  }

  function handleEditor(taskIndex: number, checked: boolean) {
      // taskIndex = revIndex(tasks, taskIndex)
      const state2 = update(tasks, { [taskIndex]: { isedit: { $set: checked} } });
     // const state2 = update(tasks, { [taskIndex]: { $toggle: ['isedit'] } });
      saveData(state2)
  }

  function onTitleUpdate(taskIndex: number, text: string) {
    // taskIndex = revIndex(tasks, taskIndex)
    // e.target.value
    const state2 = update(tasks, { [taskIndex]: { title: { $set: text } } });
    saveData(state2)
  }

  function onTaskUpdate(noteId: string, text: string) {
    // taskIndex = revIndex(tasks, taskIndex)
    addOrUpdateNoteText(noteId, text);
    return saveText(noteId, text);
  }

  function deleteText(noteId: string) {
    if (props.userProfile == null) {
      return
    };
    const email: string = props.userProfile.email as string;
    const collref = collection(db, 'taskCollection', email, "notes");
    const docref = doc(collref, noteId);
    deleteDoc(docref).catch(function (error) {
      console.error('Error deleting document to Firebase Database', error);
    });
  }

  function saveText(taskId: string, text: string) {
    if (props.userProfile == null) {
      return
    };
    const email: string = props.userProfile.email as string;
    const collref = collection(db, 'taskCollection', email, "notes");
    const docref = doc(collref, taskId)
    setDoc(docref, { 'text': text }).catch(function (error) {
      console.error('Error writing new note to Firebase Database', error);
      return false;
    });
    return true;
  }

  function saveData(notes: INote[]) {
    setTasks(notes);
    if (props.userProfile == null) {
      return
    };
    const email: string = props.userProfile.email as string;
    const collref = collection(db, 'taskCollection');
    const docref = doc(collref, email)
    setDoc(docref, { 'allnotes': notes }).catch(function (error) {
      console.error('Error writing new message to Firebase Database', error);
    });
  }

  function addTask() {
    var currSet = getNoteIdSet(tasks);
    const noteId = generateUniqueString(currSet, 2)
    const newtasks = update(tasks, { $unshift: [{ title: "Title", noteId: noteId, text: "", collapsed: false, isedit: true }] });
    saveData(newtasks)
  };

  function deleteTask(taskIndex: number) {
    if (tasks.length <= 1) return;
    const noteId = tasks[taskIndex].noteId;
    // taskIndex = revIndex(tasks, taskIndex)
    const newTasks = update(tasks, { $splice: [[taskIndex, 1]] });
    saveData(newTasks);
    deleteText(noteId);
  };

  function moveUp(taskIndex: number) {
    // taskIndex = revIndex(tasks, taskIndex)
    if (taskIndex === 0) return
    const item = tasks[taskIndex - 1]
    const newTasks = update(tasks, { $splice: [[taskIndex - 1, 1]] })
    const newTasks1 = update(newTasks, { $splice: [[taskIndex, 0, item]] })
    saveData(newTasks1)
  }

  function moveDown(taskIndex: number) {
    // taskIndex = revIndex(tasks, taskIndex)
    if (taskIndex >= tasks.length - 1) return
    const item = tasks[taskIndex]
    const newTasks = update(tasks, { $splice: [[taskIndex, 1]] })
    const newTasks1 = update(newTasks, { $splice: [[taskIndex + 1, 0, item]] })
    saveData(newTasks1)
  }

  function stopPropagationTitle(index: number) {
    // const taskIndex = revIndex(tasks, index)
    if (tasks[index].collapsed) {
        handleExpandClick(index)
    };
  }

  function getNoteContent(task: INote) {
    // console.log("Got text", task.noteId, contents)
    return contents[task.noteId]?.text ?? ""
  }

  return (
    <Paper sx={{ /* maxWidth: 936, */ margin: 'auto', overflow: 'hidden' }}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Toolbar>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <SearchIcon color="inherit" sx={{ display: 'block' }} />
            </Grid>
            <Grid item xs>
                <NoteSearch tasks={tasks} texts={contents} onTitleClick = {stopPropagationTitle}/>
            </Grid>
            <Grid item>
              <Button variant="contained" sx={{ mr: 1 }} onClick={addTask}>
                Add note
              </Button>
              <Tooltip title="Reload">
                <IconButton>
                  <RefreshIcon color="inherit" sx={{ display: 'block' }} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box sx={{ my: 5, mx: 2 }}>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Grid container spacing={16} alignItems="center" >
            <Grid item xs >
              {[...tasks].map((task, index) =>
                <Chip
                  label={task.title}
                  key={index}
                  color={task.collapsed ? "secondary" : "primary"}
                  onClick={() => handleExpandClick(index)}
                  variant="outlined"
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={16} margin="10" >
            <Grid item xs>
              {tasks.length > 0 && (
                [...tasks].map((task, index) =>
                  !task.collapsed && (
                    <Grid container spacing={8} margin="10" key={index} id={"iid-" + task.noteId}>
                      {/*Nested grid to do row, later may add multiple column per row*/}
                      <Grid item xs zeroMinWidth>
                        <Note
                              index = {index}
                              task = {task}
                              taskText = {getNoteContent(task)}
                              onTitleUpdate = {onTitleUpdate}
                              onToggleEdit = {handleEditor}
                              onMoveUp = {moveUp}
                              onMoveDown = {moveDown}
                              onDelete = {deleteTask}
                              handleExpand = {handleExpandClick}
                              onTaskUpdate = {onTaskUpdate}
                        />
                      </Grid>
                    </Grid>)
                )
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}
