import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Navigator from './Navigator';
import Content from './Content';
import Header from './Header';
import { type User } from 'firebase/auth'
import { signInWithPopup, signOut } from 'firebase/auth'
import { auth, Providers } from "../config/firebase";
import { Routes, BrowserRouter, Route } from 'react-router-dom';
import useLocalStorageState from '@mui/utils/useLocalStorageState';
import About from './About';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://ahsank.github.io">
        Ahsan Khan
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

export function useColorSchemeShim() {
  const [mode, setMode] = useLocalStorageState('mui-mode', 'system');
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', { noSsr: true });
  const systemMode = prefersDarkMode ? 'dark' : 'light';

  return {
    mode,
    systemMode,
    setMode,
  };
}
// const ColorSchemeContext = React.createContext(undefined);
// const useColorScheme = () => {
//   const value = React.useContext(ColorSchemeContext);
//   if (!value) {
//     throw new Error('MUI: `useColorScheme` must be called under <CssVarsProvider />');
//   }
//   return value;
// };

function getThemeMode() {
  var lightMode = true;
  try {
    var mode = localStorage.getItem('mui-system') || '${defaultMode}';
    if (mode === 'system') {
      // handle system mode
      var mql = window.matchMedia('(prefers-color-scheme: dark)');
    if (mql.matches) {
      lightMode = false;
    }
  }
  if (mode == 'dark') lightMode = false;
} catch(e){}
return lightMode;
}

let theme = createTheme({
  palette: {
    // mode: getThemeMode() ? 'light': 'dark',
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#081627',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.15)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#4fc3f7',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: theme.spacing(2),
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 200;

export default function Paperbase() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [userProfile, setUserProfile] = React.useState<User | null>(null);
  const isSmUp = useMediaQuery(theme.breakpoints.up('md'));

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const customtheme = React.useMemo(()=>
      createTheme(
        {
          ...theme,
          palette: {
            mode: prefersDarkMode ? 'dark': 'light',
          },

    
      }),
    [prefersDarkMode],
  );


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  React.useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUserProfile(user);
      }
    });
  }, )

  // const componentDidMount = () => {
  //   auth.onAuthStateChanged((user) => {
  //     if (user) {
  //       setUserProfile(user);
  //     }
  //   });
  // }

  const logout = () => {
    signOut(auth)
      .then(() => {
        setUserProfile(null);
      });
  }
  const login = () => {
	  signInWithPopup(auth, Providers.google)
	    .then((result) => {
		    setUserProfile(result.user);
	    });
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={customtheme}>
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
          <CssBaseline />
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          >
            {isSmUp ? null : (
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              />
            )}
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              sx={{ display: { sm: 'block', xs: 'none' } }}
            />
          </Box>
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Header userProfile={userProfile} onDrawerToggle={handleDrawerToggle} logout={logout} login={login}/>

            <Box component="main" sx={{ flex: 1, py: 6, px: 4,  }}>
            <Routes>
                <Route  path="/" element={<Content userProfile={userProfile}/>}>
                </Route>
                <Route path="/about" element={<About/>} />
            </Routes>
            </Box>
            <Box component="footer" sx={{ p: 2, }}>
              <Copyright />
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </BrowserRouter>
  );
}
