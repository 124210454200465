import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import MyMarkdown from './MyMarkdown';
import { Stack } from '@mui/material';
import MonacoMarkdownEditor from './MonacoEditor';
interface INoteProps {
    index: number;
    task: { title: string;
        noteId: string;
        collapsed: boolean;
        isedit: boolean;};
    taskText: string;
    onTitleUpdate(taskIndex: number, text: string): void;
    onTaskUpdate(noteId: string, text: string): void;
    onMoveUp(taskIndex: number): void;
    onMoveDown(taskIndex: number): void;
    onDelete(taskIndex: number): void;
    handleExpand(taskIndex: number): void;
    onToggleEdit(taskIndex: number, checked: boolean): void;
  };

  
function DeleteDialog(props: {onDelete:  () => void}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseCancel = () => {
    setOpen(false);
  };

  const handleCloseDelete = () => {
      props.onDelete();
    setOpen(false);
  };
  
  return (
    <span>
      <IconButton aria-label="Delete" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleCloseCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure to delete?"}</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCloseDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}


function Note(props: INoteProps) {

function onTitleInput(e: React.ChangeEvent<any>) {props.onTitleUpdate(props.index, e.target.value) }
function onCheck(e: React.ChangeEvent<any>) {props.onToggleEdit(props.index, e.target.checked)}
function moveUpClick() { props.onMoveUp(props.index)    }
function moveDownClick() {props.onMoveDown(props.index) }
function deleteClick() {props.onDelete(props.index)}
function collapseClick() {props.handleExpand(props.index)}
function taskEdit(e: React.ChangeEvent<any>) {props.onTaskUpdate(props.task.noteId, e.target.value)}
  

  return (
    <Card sx={{ display: 'block',  m: 1, zIndex: 2, width: '120'}}>
      <CardHeader
        title={
            <TextField
                placeholder="Title"
                value={props.task.title}
                key={props.index}
                onInput={onTitleInput}
                variant='standard'
                disabled={!props.task.isedit}
                fullWidth
            />
        }
        action={
          <div>
            <Checkbox
                checked={props.task.isedit ? props.task.isedit : false}
                onChange={onCheck}
                color="default"
                inputProps={{
                    'aria-label': 'edit item',
                }}
            />
            <IconButton aria-label="Up" onClick={moveUpClick}>
                <ArrowUpwardIcon />
            </IconButton>
            <IconButton aria-label="Down" onClick={moveDownClick}>
                <ArrowDownwardIcon />
            </IconButton>
            <DeleteDialog onDelete={deleteClick}/>
            <IconButton
                onClick={collapseClick}
                aria-expanded={!props.task.collapsed}
                aria-label="Show more"
            >
                <ExpandMoreIcon />
            </IconButton>
          </div>
        }
      />
      <Collapse in={!props.task.collapsed} timeout="auto" unmountOnExit>
        <CardContent>  {
            props.task.isedit ?
            <Stack>
            {/* <DemoEditor
                key={props.index}
                id={props.index.toString()}
                value={props.task.text}
                onChange={taskEdit}
                copyButtonProps={{}}
                language='md'
            >
              <TextField/>
            </DemoEditor> */}


            <MonacoMarkdownEditor
              language='md'
              onValueChange = {(value) => value && props.onTaskUpdate(props.task.noteId, value)}
              value = {props.taskText}
            >
            </MonacoMarkdownEditor>
            {/* <RichMarkdownEditor
              language='md'
              onValueChange = {(value) => props.onTaskUpdate(props.task.noteId, value)}
              value = {props.taskText}
              previewOnly =  {!props.task.isedit}
            >
            </RichMarkdownEditor> */}
            </Stack>
            :
            // <RichMarkdownEditor
            //   language='md'
            //   value = {props.taskText}
            //   onValueChange={(value)=>{}}
            //   previewOnly={true}
            // ></RichMarkdownEditor>
            <div className="markdown">
                <MyMarkdown>{props.taskText}</MyMarkdown>
            </div>

        }
 
        </CardContent>

      </Collapse>
    </Card>
  )
}
export default Note;
