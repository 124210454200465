import * as React from 'react';
import "@wcj/dark-mode";
import Editor from '@monaco-editor/react';


const code = `# title\n\nHello World!\n\n`;


interface MonacoEditorProps extends React.HTMLAttributes<HTMLDivElement> {
    language: string;
    onValueChange: (value: string | undefined) => void;
    value: string;
  }
export default function MonacoMarkdownEditor(props: MonacoEditorProps) {
  const options = {
    selectOnLineNumbers: true,
    automaticLayout: true,
    quickSuggestionsDelay: 500,
    quickSuggestions: false,
    fontSize: 13,
    // fontFamily: 'monospace'
  };
  return (
    <div>
      <div className="App">
        <Editor
          height="70vh"
          defaultLanguage="markdown"
          value={props.value}
          options={options}
          onChange={(val: string | undefined) => {val && props.onValueChange(val);}}
        />
      </div>
    </div>
  );
}
